import { useStaticQuery, graphql } from 'gatsby'

export const useFileImage = () => {
  const image = useStaticQuery(
    graphql`
      query {
        noneImage: file(relativePath: { eq: "none-image.png" }) {
          childImageSharp {
            fluid(maxWidth: 300, maxHeight: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return image
}
