import { useStaticQuery, graphql } from 'gatsby'

export const useFetchBuses = () => {
  const { wordpress } = useStaticQuery(
    graphql`
      query {
        wordpress {
          buses(last: 999) {
            nodes {
              busId
              shortBusesCard {
                fieldGroupName
                perfomance {
                  advantages
                  busFloor
                  eco
                  fieldGroupName
                  numberPassengers
                  seat
                  years
                }
                previewImage {
                  sourceUrl
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 330, maxHeight: 330) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return wordpress.buses.nodes
}
