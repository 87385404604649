export const useBusCardSettings = () => {
  const _handleEcoType = (type) => {
    switch (type) {
      case 'eco-1':
        return 'Євро 1'
      case 'eco-2':
        return 'Євро 2'
      case 'eco-3':
        return 'Євро 3'
      case 'eco-4':
        return 'Євро 4'
      case 'eco-5':
        return 'Євро 5'
      case 'eco-6':
        return 'Євро 6'
    }
  }

  const _handleSeatType = (type) => {
    switch (type) {
      case 'seat-1':
        return 'розкладні'
      case 'seat-2':
        return 'нерозкладні'
    }
  }

  const _handleActiveIcon = (arr, type) => {
    if (arr) {
      const inArray = arr.filter((item) => item === type).length > 0
      return inArray && 'active'
    }
  }

  return {
    handleEcoType: _handleEcoType,
    handleSeatType: _handleSeatType,
    handleActiveIcon: _handleActiveIcon,
  }
}
