import React, { useState, useEffect } from 'react'

import IconArrow from '@/assets/images/svg/arrow.svg'
import IconPlus from '@/assets/images/svg/plus.svg'

export const FilterItem = ({ title, data, onChange, selected }) => {
  // const [selected, setSelected] = useState(null)

  const _handleAdd = (value) => {
    if (selected) {
      onChange([...selected, value])
    } else {
      onChange([value])
    }
  }

  const _handleRemove = (value) => {
    const newSelected = selected.filter((item) => item !== value)
    if (newSelected.length > 0) {
      onChange(newSelected)
    } else {
      onChange(null)
    }
  }

  return (
    <div className="selector">
      <div className="selector__box">
        <div className="selector__title">{title}</div>
        <IconArrow className="selector__arrow" />
        <IconPlus className="selector__plus" />
        <div className="selector__list">
          {data.map((item, index) => {
            const isSelected = selected
              ? selected.filter((selectItem) => selectItem === item.value)
                  .length > 0
              : false
            if (isSelected) {
              return (
                <div
                  key={item.value}
                  className="selector__item active"
                  onClick={() => _handleRemove(item.value)}
                >
                  {item.label}
                </div>
              )
            }
            return (
              <div
                key={item.value}
                className="selector__item"
                onClick={() => _handleAdd(item.value)}
              >
                {item.label}
              </div>
            )
          })}
        </div>
      </div>
      {/* <div className="selector__item-select_container">
        {selected &&
          selected.map((item) => (
            <div
              key={item}
              className="selector__item-select"
              onClick={() => _handleRemove(item)}
            >
              {data.filter((dataItem) => dataItem.value === item)[0].label}
            </div>
          ))}
      </div> */}
    </div>
  )
}
