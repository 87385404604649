import React from 'react'
import Img from 'gatsby-image'

import IconConditioner from '@/assets/images/svg/conditioner.svg'
import IconTv from '@/assets/images/svg/tv.svg'
import IconMusic from '@/assets/images/svg/music.svg'
import { useModalForm } from '@/components'

import { useBusCardSettings, useFileImage } from '../../hooks'

export const BusCard = ({ data }) => {
  const { setModalOpen } = useModalForm()
  const {
    handleEcoType,
    handleSeatType,
    handleActiveIcon,
  } = useBusCardSettings()
  const { noneImage } = useFileImage()
  const path = data.shortBusesCard.perfomance

  return (
    <>
      <div className="buses__card">
        <div key={data.busId} className="buses__card-img-box">
          {data.shortBusesCard.previewImage ? (
            <Img
              fluid={
                data.shortBusesCard.previewImage.imageFile.childImageSharp.fluid
              }
              objectFit="cover"
            />
          ) : (
            <Img fluid={noneImage.childImageSharp.fluid} objectFit="cover" />
          )}
        </div>
        <div className="buses__card-wrpr">
          <div className="buses__card-info">
            Кількість місць для пасажирів: <b>{path.numberPassengers}</b>
          </div>
          <div className="buses__card-info">
            Рік випуску: <b>{path.years}</b>
          </div>
          <div className="buses__card-info">
            Клас емісії викидів: <b>{handleEcoType(path.eco)}</b>
          </div>
          <div className="buses__card-info">
            Cидіння: <b>{handleSeatType(path.seat)}</b>
          </div>
          <div className="buses__card-info">Основні переваги:</div>
          <div className="buses__card-flex">
            <div className="buses__card-services">
              <div className="buses__card-services__icon">
                <IconConditioner
                  className={`icon ${handleActiveIcon(
                    path.advantages,
                    'conditioner'
                  )}`}
                />
              </div>
              <div className="buses__card-services__icon">
                <IconTv
                  className={`icon ${handleActiveIcon(path.advantages, 'tv')}`}
                />
              </div>
              <div className="buses__card-services__icon">
                <IconMusic
                  className={`icon ${handleActiveIcon(
                    path.advantages,
                    'music'
                  )}`}
                />
              </div>
            </div>
            <div
              className="btn btn_gradient buses-btn open-form"
              onClick={() => setModalOpen(true)}
            >
              Замовити
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
