import React, { useEffect, useState, useCallback } from 'react'

import { Layout, SEO } from '@/components'
import IconPlus from '@/assets/images/svg/plus.svg'

import { useFetchBuses } from './hooks'
import { BusCard, FilterItem } from './components'

const places = [
  {
    value: 1,
    label: 'До 20',
  },
  {
    value: 2,
    label: 'Від 20 до 28',
  },
  {
    value: 3,
    label: 'Більше 28',
  },
]

const floors = [
  {
    value: 1,
    label: 'Одноповерховий',
  },
  {
    value: 2,
    label: 'Двоповерховий',
  },
]

const advantages = [
  {
    value: 'conditioner',
    label: 'Кондиционер',
  },
  {
    value: 'tv',
    label: 'Телевізор',
  },
  {
    value: 'music',
    label: 'Музика',
  },
]

export const Buses = ({ pageContext: { seo } }) => {
  const [filter, setFilter] = useState({
    places: null,
    floors: null,
    advantages: null,
  })
  const [buses, setBuses] = useState(null)
  const fetchedBuses = useFetchBuses()

  const _handleChangeFilter = (value) => {
    setFilter((prev) => ({ ...prev, ...value }))
  }

  const _handleRemovePlacesFilter = (value) => {
    const newValue = filter.places.filter((place) => place !== value)
    console.log(newValue, value)
    setFilter((prev) => ({
      ...prev,
      places: newValue.length > 0 ? newValue : null,
    }))
  }

  const _handleRemoveFloorsFilter = (value) => {
    const newValue = filter.floors.filter((floor) => floor !== value)
    console.log(newValue, value)
    setFilter((prev) => ({
      ...prev,
      floors: newValue.length > 0 ? newValue : null,
    }))
  }

  const _handleRemoveAdvantagesFilter = (value) => {
    const newValue = filter.advantages.filter(
      (advantage) => advantage !== value
    )
    console.log(newValue, value)
    setFilter((prev) => ({
      ...prev,
      advantages: newValue.length > 0 ? newValue : null,
    }))
  }

  const _filterBuses = useCallback(() => {
    const filteredBuses = fetchedBuses.filter((bus) => {
      if (!filter.places && !filter.floors && !filter.advantages) {
        return true
      }
      if (filter.places) {
        const busPlaces = bus.shortBusesCard.perfomance.numberPassengers
        const hasValue =
          filter.places.filter((place) => {
            switch (place) {
              case 1:
                return busPlaces < 20
              case 2:
                return busPlaces >= 20 && busPlaces <= 28
              case 3:
                return busPlaces > 28
            }
          }).length > 0
        if (!hasValue) {
          return false
        }
      }
      if (filter.floors) {
        const busFloor = bus.shortBusesCard.perfomance.busFloor
        const hasValue =
          filter.floors.filter((floor) => floor === +busFloor).length > 0
        if (!hasValue) {
          return false
        }
      }
      if (filter.advantages) {
        const busAdvantages = bus.shortBusesCard.perfomance.advantages
        if (busAdvantages.length === 0) {
          return false
        }
        const hasValue =
          filter.advantages.filter(
            (advantage) =>
              busAdvantages.filter((busAdvantage) => busAdvantage === advantage)
                .length > 0
          ).length > 0
        if (!hasValue) {
          return false
        }
      }
      return true
    })
    setBuses(filteredBuses)
  }, [filter, fetchedBuses])

  useEffect(() => {
    if (!buses) {
      setBuses(fetchedBuses)
    }
  }, [buses, fetchedBuses])

  useEffect(() => {
    _filterBuses()
  }, [_filterBuses])

  return (
    <Layout>
      <SEO
        description={seo.metaDesc}
        title={seo.title}
        meta={[
          {
            name: `keywords`,
            content: seo.focuskw,
          },
        ]}
      />
      <section className="section-first">
        <div className="container">
          <h1 className="title_h2">Наші автобуси</h1>
        </div>
        <section className="buses">
          <div className="container">
            <div className="buses__filter">
              <FilterItem
                title="Кількість місць"
                data={places}
                selected={filter.places}
                onChange={(places) => _handleChangeFilter({ places })}
              />
              <FilterItem
                title="Етажність автобуса"
                data={floors}
                selected={filter.floors}
                onChange={(floors) => _handleChangeFilter({ floors })}
              />
              <FilterItem
                title="Види комфорту"
                data={advantages}
                selected={filter.advantages}
                onChange={(advantages) => _handleChangeFilter({ advantages })}
              />
            </div>
            <div className="filter-container">
              {filter.places &&
                filter.places.map(
                  (item) =>
                    item && (
                      <div
                        key={item}
                        className="selector__item-select"
                        onClick={() => _handleRemovePlacesFilter(item)}
                      >
                        {
                          places.filter((place) => place.value === item)[0]
                            .label
                        }
                        <IconPlus className="item-select-close" />
                      </div>
                    )
                )}
              {filter.floors &&
                filter.floors.map(
                  (item) =>
                    item && (
                      <div
                        key={item}
                        className="selector__item-select"
                        onClick={() => _handleRemoveFloorsFilter(item)}
                      >
                        {
                          floors.filter((place) => place.value === item)[0]
                            .label
                        }
                        <IconPlus className="item-select-close" />
                      </div>
                    )
                )}
              {filter.advantages &&
                filter.advantages.map(
                  (item) =>
                    item && (
                      <div
                        key={item}
                        className="selector__item-select"
                        onClick={() => _handleRemoveAdvantagesFilter(item)}
                      >
                        {
                          advantages.filter((place) => place.value === item)[0]
                            .label
                        }
                        <IconPlus className="item-select-close" />
                      </div>
                    )
                )}
            </div>
            <div className="buses__list">
              {buses &&
                buses.map((bus) => <BusCard key={bus.busId} data={bus} />)}
            </div>
          </div>
        </section>
      </section>
    </Layout>
  )
}
